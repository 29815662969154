import React, { Suspense, lazy } from 'react'
import { Route, Switch } from 'react-router-dom'
import { FallbackView } from '../../_metronic/partials'
import DashboardWrapper from '../pages/dashboard/DashboardWrapper'

export function PrivateRoutes() {
    const UsersPage = lazy(() => import('../modules/users/Users'))
    const RolesPage = lazy(() => import('../modules/roles/RolesPage'))
    const Tenants = lazy(() => import('../modules/tenants/Tenants'))
    const BusinessTypes = lazy(() => import('../modules/business-types/BusinessTypes'))
    const ProposalSections = lazy(() => import('../modules/connect/proposal-sections/ProposalSections'))
    const Proposals = lazy(() => import('../modules/connect/proposals/Proposals'))
    const BatchJobs = lazy(() => import('../modules/batch-jobs/BatchJobs'))
    const Sites = lazy(() => import('../modules/sites/Sites'))
    const SiteTypes = lazy(() => import('../modules/site-types/SiteTypes'))
    const NoticeTypes = lazy(() => import('../modules/notice-types/NoticeTypes'))
    const Notices = lazy(() => import('../modules/notices/Notices'))
    const Invites = lazy(() => import('../modules/invites/Invites'))
    const LeaveSiteReasons = lazy(() => import('../modules/leave-site-reason/LeaveSiteReasons'))
    const WorkOrderStatus = lazy(() => import('../modules/work-order-status/WorkOrderStatus'))
    const QuoteStatus = lazy(() => import('../modules/quote-status/QuoteStatus'))
    const WorkOrderTypes = lazy(() => import('../modules/work-order-types/WorkOrderTypes'))
    const WorkOrders = lazy(() => import('../modules/work-order/WorkOrders'))
    const Priorities = lazy(() => import('../modules/priorities/Priorities'))
    const WorkScopes = lazy(() => import('../modules/work-scopes/WorkScopes'))
    const TenantMappings = lazy(() => import('../modules/tenant-mappings/TenantMappings'))
    const WorkOrderCategories = lazy(
        () => import('../modules/work-order-categories/WorkOrderCategories')
    )
    const Messages = lazy(() => import('../modules/messages/Messages'))
    const CategoryWorkScopes = lazy(
        () => import('../modules/category-work-scopes/CategoryWorkScopes')
    )
    const ChargeTypes = lazy(() => import('../modules/charge-types/ChargeTypes'))
    const CheckIns = lazy(() => import('../modules/check-ins/CheckIns'))
    const Calendars = lazy(() => import('../modules/calendars/Calendars'))
    // const Quotes = lazy(() => import('../modules/quotes/Quotes'))
    const CheckListsTemplates = lazy(
        () => import('../modules/check-list-templates/CheckListTemplates')
    )
    const DocumentTypes = lazy(() => import('../modules/document-types/DocumentTypes'))
    const Library = lazy(() => import('../modules/library/Library'))
    const Contracts = lazy(() => import('../modules/contracts/Contracts'))
    const AssetCategories = lazy(() => import('../modules/asset-categories/AssetCategories'))
    const AssetTypes = lazy(() => import('../modules/asset-types/AssetTypes'))
    const Assets = lazy(() => import('../modules/assets/Assets'))
    const Schedules = lazy(() => import('../modules/schedules/Schedules'))
    const ContractTypes = lazy(() => import('../modules/contract-types/ContractTypes'))
    const ContractCategories = lazy(() => import('../modules/contract-categories/ContractCategories'))
    const StatusFlows = lazy(() => import('../modules/status-flows/StatusFlows'))
    const HazardStatus = lazy(() => import('../modules/hazard-status/HazardStatus'))
    const HazardType = lazy(() => import('../modules/hazard-type/HazardType'))
    const Hazards = lazy(() => import('../modules/hazards/Hazards'))
    const IncidentStatus = lazy(() => import('../modules/incident-status/IncidentStatus'))
    const IncidentType = lazy(() => import('../modules/incident-type/IncidentType'))
    const IncidentCause = lazy(() => import('../modules/incident-cause/IncidentCause'))
    const ActionStatus = lazy(() => import('../modules/action-status/ActionStatus'))
    const ActionType = lazy(() => import('../modules/action-type/ActionType'))
    const Incidents = lazy(() => import('../modules/incidents/Incidents'))
    const RateCards = lazy(() => import('../modules/connect/rate-cards/RateCards'))
    const RateCardTypes = lazy(() => import('../modules/connect/rate-card-types/RateCardTypes'))
    const InvolvementTypes = lazy(() => import('../modules/connect/involvement-types/InvolvementTypes'))
    const Exclusions = lazy(() => import('../modules/connect/exclusions/Exclusions'))

    return (
        <Suspense fallback={<FallbackView />}>
            <Switch>
                <Route path='/dashboard' component={DashboardWrapper} />
                <Route path='/users' component={UsersPage} />
                <Route path='/roles' component={RolesPage} />
                <Route path='/clients' component={Tenants} />
                <Route path='/contractors' component={Tenants} />
                <Route path='/account' component={Tenants} />
                <Route path='/business-types' component={BusinessTypes} />
                <Route path='/proposal-sections' component={ProposalSections} />
                <Route path='/proposals' component={Proposals} />
                <Route path='/batch-jobs' component={BatchJobs} />
                <Route path='/sites' component={Sites} />
                <Route path='/site-types' component={SiteTypes} />
                <Route path='/notice-types' component={NoticeTypes} />
                <Route path='/notices' component={Notices} />
                <Route path='/invites' component={Invites} />
                <Route path='/leave-site-reason' component={LeaveSiteReasons} />
                <Route path='/work-order-status' component={WorkOrderStatus} />
                <Route path='/quote-status' component={QuoteStatus} />
                <Route path='/work-order-types' component={WorkOrderTypes} />
                <Route path='/work-orders' component={WorkOrders} />
                <Route path='/priorities' component={Priorities} />
                <Route path='/work-scopes' component={WorkScopes} />
                <Route path='/category-work-scopes' component={CategoryWorkScopes} />
                <Route path='/tenant-mappings' component={TenantMappings} />
                <Route path='/work-order-categories' component={WorkOrderCategories} />
                <Route path='/messages' component={Messages} />
                <Route path='/charge-types' component={ChargeTypes} />
                <Route path='/check-ins' component={CheckIns} />
                <Route path='/calendars' component={Calendars} />
                <Route path='/check-list-templates' component={CheckListsTemplates} />
                <Route path='/document-types' component={DocumentTypes} />
                <Route path='/library' component={Library} />
                <Route path='/contracts' component={Contracts} />
                <Route path='/asset-categories' component={AssetCategories} />
                <Route path='/asset-types' component={AssetTypes} />
                <Route path='/assets' component={Assets} />
                <Route path='/schedules' component={Schedules} />
                <Route path='/contract-types' component={ContractTypes} />
                <Route path='/contract-categories' component={ContractCategories} />
                <Route path='/status-flows' component={StatusFlows} />
                <Route path='/hazard-status' component={HazardStatus} />
                <Route path='/hazard-type' component={HazardType} />
                <Route path='/hazards' component={Hazards} />
                <Route path='/incident-status' component={IncidentStatus} />
                <Route path='/incident-type' component={IncidentType} />
                <Route path='/incident-cause' component={IncidentCause} />
                <Route path='/action-status' component={ActionStatus} />
                <Route path='/action-type' component={ActionType} />
                <Route path='/incidents' component={Incidents} />
                <Route path='/rate-cards' component={RateCards} />
                <Route path='/rate-card-types' component={RateCardTypes} />
                <Route path='/involvement-types' component={InvolvementTypes} />
                <Route path='/exclusions' component={Exclusions} />
            </Switch>
        </Suspense>
    )
}
